import React from "react"

//plugin
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer"

// json file
import policy from "../data/privacy.json"

// assets
import purposeImage from "../assets/images/privacy-policy/purpose-collection.png"
import collectionImage from "../assets/images/privacy-policy/data-collection.png"
import cookiesImage from "../assets/images/privacy-policy/cookies.png"
import securityImage from "../assets/images/privacy-policy/security.png"
import thirdPartyImage from "../assets/images/privacy-policy/third-party.png"

const privacyContent = [...policy]

const getImage = privacyImage => {
  switch (privacyImage) {
    case "data-collection-image":
      return collectionImage

    case "purpose-collection-image":
      return purposeImage

    case "security-image":
      return securityImage

    case "cookies-image":
      return cookiesImage

    case "third-party-image":
      return thirdPartyImage

    default:
      return null
  }
}

const style = StyleSheet.create({
  privacyTitleHolder: {
    width: "100%",
    paddingBottom: 40,
  },
  contentHolder: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  titlePrivacy: {
    fontSize: 24,
    fontWeight: "bold",
  },
  imgPrivacy: {
    display: "block",
    width: "100%",
    maxWidth: 227,
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
  },
  subTextHolder: {
    paddingLeft: 60,
  },
  text: {
    marginTop: 10,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,

    fontSize: 14,
  },
  privacyContainer: {
    paddingTop: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingLeft: 30,
  },
})

const PrivacyList = () => {
  return (
    <Document file="privacy.pdf">
      {/* <Page size="A4" style={style.privacyContainer}>
        <View className="privacy-title-holder" style={style.privacyTitleHolder}>
          <Text className="title-privacy" style={style.titlePrivacy}>
            Privacy Policy
          </Text>
          <Text style={style.text}>
            Servbees Pte. Ltd. and its affiliates (collectively “Servbees”,
            “we”, or “us”) is a marketplace platform company that provides an
            on-demand service (“Service”) that allows businesses (“Customers”)
            to connect with Servbees’s network of geographically distributed
            independent service providers (“Servbees”) for various short-term
            assignments as well as sellers providing different items and
            merchandise for sale for users looking for specific items / service
            or anything they need.
          </Text>
          <Text style={style.text}>
            When you use our Service as a Customer or Servbees (collectively
            “user” or “you”), you share your information with us. We respect
            your privacy and want to be upfront about the information we
            collect, how we use it, whom we share it with, and inform you about
            the controls we give you to access, change or deactivate your
            account information.
          </Text>
          <Text style={style.text}>
            Our Privacy Policy describes our practices regarding personal
            information (“Personal Data”) that we collect from users through our
            websites, the Servbees mobile application, the Servbees platform,
            and related media or services.
          </Text>
          <Text style={style.text}>
            The important thing we want you to know is that we use information
            collected only for the purpose of providing and improving the
            Service to you. This Privacy Policy is incorporated into, and
            considered a part of, the Terms of Service or Customer Agreement
            that you agree to, when you use our Service. If you have questions
            about anything you read here, just contact us at
            https://servbees.com/support.
          </Text>
        </View>
      </Page> */}
      <Page size="A4" style={style.privacyContainer}>
        <View>
          {privacyContent.map(privacy => {
            let img = privacy.image ? getImage(privacy.image) : null
            return (
              <View
                id={privacy.name}
                className="content-holder"
                style={style.contentHolder}
              >
                <Text className="title-privacy" style={style.titlePrivacy}>
                  {privacy.href === "Privacy" ? (
                    privacy.name
                  ) : (
                    <>
                      {privacy.id}.&nbsp;{privacy.name}
                    </>
                  )}
                </Text>
                <Image
                  src={img}
                  alt={privacy.image ? "illustration" : null}
                  className="img-privacy"
                  style={style.imgPrivacy}
                  safePath={img}
                />
                {privacy.content.map(sub => {
                  return (
                    <View>
                      <Text style={style.text}>
                        {sub.text ? sub.text : null}
                      </Text>
                      <View
                        className="sub-text-holder"
                        style={style.subTextHolder}
                      >
                        {sub.textSub.map(textSub => {
                          return (
                            <Text style={style.text}>
                              {textSub ? textSub : null}
                            </Text>
                          )
                        })}
                      </View>
                    </View>
                  )
                })}
                <Text style={style.text}>
                  {privacy.subcontent ? privacy.subcontent : null}
                </Text>
                <Text style={style.text}>
                  {privacy.subsubcontent ? privacy.subsubcontent : null}
                </Text>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )
}

export default PrivacyList
