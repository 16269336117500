import React, { useState } from "react"

//plugin
import ReactHtmlParser from "react-html-parser"
import { pdf } from "@react-pdf/renderer"
import { saveAs } from "file-saver"

// components
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrivacyCard from "../components/privacyCard"
import PrivacyMenu from "../components/privacyMenu"
import TableContents from "../components/table-of-content"
import PrivacyList from "../components/privacyList"
import Banner from "../components/banner"
import SectionSearch from "../components/section-search"
import Cta from "../components/section-cta"

// json file
import policy from "../data/privacy.json"

// assets
import purposeImage from "../assets/images/privacy-policy/purpose-collection.svg"
import collectionImage from "../assets/images/privacy-policy/data-collection.svg"
import cookiesImage from "../assets/images/privacy-policy/cookies.svg"
import securityImage from "../assets/images/privacy-policy/security.svg"
import thirdPartyImage from "../assets/images/privacy-policy/third-party.svg"
import iconLike from "../assets/images/privacy-policy/icon-like.svg"
import iconLikeMobile from "../assets/images/privacy-policy/icon-like-mobile.svg"
import iconLock from "../assets/images/privacy-policy/icon-lock.svg"
import iconUser from "../assets/images/privacy-policy/icon-user-alt.svg"
import iconDown from "../assets/images/privacy-policy/arrow-down.svg"
import tableImage from "../assets/images/privacy-policy/privacy-side-image.svg"

const cardContent = [
  {
    icon: iconLike,
    iconMobile: iconLikeMobile,
    title: `You're In Control`,
    description:
      "We encourage you to review details before posting, use the controls for your information settings, and always be aware of the data that you share with us and how we use it.",
  },
  {
    icon: iconLock,
    title: "Safety Above All",
    description:
      "We value your trust. Our team consistently takes all necessary steps to protect your data and ensure the security of your Servbees experience.",
  },
  {
    icon: iconUser,
    title: "Why Share Your Info?",
    description:
      "We collect personal information so that we can provide the service that you require, and deliver to you offers and information that that are meaningful to you.",
  },
]

const privacyContent = [...policy]

const getImage = privacyImage => {
  switch (privacyImage) {
    case "data-collection-image":
      return collectionImage

    case "purpose-collection-image":
      return purposeImage

    case "security-image":
      return securityImage

    case "cookies-image":
      return cookiesImage

    case "third-party-image":
      return thirdPartyImage

    default:
      return null
  }
}

const PrivacyPolicy = ({ location }) => {
  const [keyword, setKeyword] = useState("")

  const handleSearch = e => {
    setKeyword(e.target.value)
  }

  const dynamicSearch = () => {
    const filters = policy.filter(names =>
      names.name.toLowerCase().includes(keyword.toLowerCase())
    )
    return filters
  }

  const handleClear = () => {
    setKeyword("")
  }

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleDownloadPdf = () => {
    saveAs(
      `/assets/privacy-policy/SERVBEES_Jul_2022_PRIVACY_POLICY.pdf`,
      "SERVBEES_Jul_2022_PRIVACY_POLICY",
      "pdf"
    )
  }

  return (
    <Layout className="privacy bg-design vector-dash blue-footer">
      <SEO
        pageTitle="Privacy Policy"
        title="Privacy Policy | Servbees"
        description="At Servbees, we make sure that you're safe and secure as you use our services. No buzzkill in the future—just busy bee-ing!"
      />
      {keyword === "" ? (
        <TableContents
          tableContent={privacyContent}
          location={"/privacy-policy"}
          panelImg={tableImage}
          href={"Privacy"}
        />
      ) : (
        ""
      )}
      <Banner
        title="Privacy Policy"
        keyword={keyword}
        handleSearch={handleSearch}
        handleClear={handleClear}
      />
      <div className="privacy-wrapper">
        {keyword !== "" ? (
          <SectionSearch
            content={dynamicSearch()}
            keyword={keyword}
            location={"/privacy-policy"}
          />
        ) : (
          <>
            <div className="privacy-holder">
              <div className="show-card-mobile">
                <img src={cardContent[0].iconMobile} alt="like icon" />
                <p className="card-mobile-title">{cardContent[0].title}</p>
                <p className="card-mobile-desc">{cardContent[0].description}</p>
              </div>
              <div className="cards-holder">
                {cardContent.map(privacy => {
                  return (
                    <PrivacyCard
                      icon={privacy.icon}
                      title={privacy.title}
                      description={privacy.description}
                    />
                  )
                })}
              </div>
              <div className="cards-mobile">
                <div className="scroller">
                  <div className="item">
                    <div className="card-title-holder">
                      <img src={cardContent[1].icon} alt="like icon" />
                      <p className="card-mobile-title">
                        {cardContent[1].title}
                      </p>
                    </div>
                    <p className="card-mobile-desc">
                      {cardContent[1].description}
                    </p>
                  </div>
                  <div className="item">
                    <div className="card-title-holder">
                      <img src={cardContent[2].icon} alt="like icon" />
                      <p className="card-mobile-title">
                        {cardContent[2].title}
                      </p>
                    </div>
                    <p className="card-mobile-desc">
                      {cardContent[2].description}
                    </p>
                  </div>
                </div>
              </div>
              <div className="sub-title-holder">
                <p className="sub-title">Servbees Privacy Policy</p>
                <img src={iconDown} alt="" />
              </div>
              <div className="privacy-content-holder">
                <PrivacyMenu location={"/privacy-policy"} />
                <div id="sectionToPdf" className="privacy-content">
                  <div className="download-holder">
                    <p>Last updated: 5 July 2022</p>
                    <button
                      className="btn-download"
                      onClick={handleDownloadPdf}
                    >
                      Download PDF
                    </button>
                    <span
                      className="btn-mobile-download"
                      onClick={handleDownloadPdf}
                      role="presentation"
                    />
                  </div>
                  <div>
                    {privacyContent.map(privacy => {
                      let img = privacy.image ? getImage(privacy.image) : null
                      return (
                        <div id={privacy.href} className="content-holder">
                          <p className="title-privacy">
                            {privacy.href === "Privacy" ? (
                              privacy.name
                            ) : (
                              <>
                                {privacy.id}.&nbsp;{privacy.name}
                              </>
                            )}
                          </p>
                          <img
                            src={img}
                            alt={privacy.image ? "illustration" : null}
                            className="img-privacy"
                          />
                          {privacy.content.map(sub => {
                            return (
                              <div>
                                <p>
                                  {sub.text ? ReactHtmlParser(sub.text) : null}
                                </p>
                                <div className="sub-text-holder">
                                  {sub.textSub.map(textSub => {
                                    return <p>{textSub ? textSub : null}</p>
                                  })}
                                </div>
                              </div>
                            )
                          })}
                          <p>
                            {privacy.subcontent ? privacy.subcontent : null}
                          </p>
                          <p>
                            {privacy.subsubcontent
                              ? privacy.subsubcontent
                              : null}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                  <div className="return-holder">
                    <p
                      className="return-text"
                      onClick={backToTop}
                      role="presentation"
                    >
                      Return to Top
                    </p>
                    <div className="download-holder">
                      <p>Last updated: 5 July 2022</p>
                      <button
                        className="btn-download"
                        onClick={handleDownloadPdf}
                      >
                        Download PDF
                      </button>
                      <span
                        role="presentation"
                        className="btn-mobile-download"
                        onClick={handleDownloadPdf}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Cta />
    </Layout>
  )
}

export default PrivacyPolicy
